import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function ErrorContainer({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        gap: '30px',
      }}
    >
      {children}
    </Box>
  );
}

ErrorContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorContainer;
