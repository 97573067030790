import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';

import logo from '../assets/images/store-central.png';
import ExternalCourierContainer from '../components/containers/ExternalCourierContainer';
import MainContainer from '../components/containers/MainContainer';
import TableRow from '../components/containers/TableRowContainer';
import DeliveryStepper from '../components/stepper/Stepper';

function Tracking({
  seller: { name: sellerName },
  trackingUrl,
  status,
  orderNumber,
  dropOffAddress,
  statusHistory,
  courier,
  hasDelivery,
}) {
  return (
    <MainContainer customStyle={{ maxWidth: '400px' }}>
      <table
        style={{ tableLayout: 'fixed' }}
        role="presentation"
        cellSpacing="20px"
      >
        <tbody>
          <tr>
            <td colSpan="100%">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  mb: 2,
                }}
              >
                <img src={logo} alt="logo" style={{ width: 180 }} />
              </Box>
            </td>
          </tr>
          <TableRow
            data={[
              { header: 'Tienda', cell: sellerName },
              { header: 'Número de orden', cell: orderNumber },
            ]}
          />
          <tr>
            <td colSpan="100%">
              <Divider flexItem />
            </td>
          </tr>
          <TableRow
            data={
              hasDelivery
                ? [
                    {
                      header: 'Dirección de entrega',
                      cell: dropOffAddress,
                    },
                  ]
                : [{ header: 'Dirección de retiro', cell: dropOffAddress }]
            }
          />
          <tr>
            <td colSpan="100%">
              <Box
                sx={{
                  padding: '20px 0px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <DeliveryStepper
                  steps={statusHistory}
                  activeStepValue={status}
                  optional="updatedAt"
                />
              </Box>
            </td>
          </tr>
          <tr>
            <td colSpan="100%">
              <ExternalCourierContainer
                courier={courier}
                trackingUrl={trackingUrl}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </MainContainer>
  );
}

Tracking.propTypes = {
  seller: PropTypes.shape({
    name: PropTypes.string,
    branded: PropTypes.bool,
  }).isRequired,
  status: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  dropOffAddress: PropTypes.string.isRequired,
  courier: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
  }).isRequired,
  trackingUrl: PropTypes.string,
  statusHistory: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      updatedAt: PropTypes.string, // can be undefined
      icon: PropTypes.node.isRequired,
    }).isRequired,
  ).isRequired,
  hasDelivery: PropTypes.bool,
};

Tracking.defaultProps = {
  hasDelivery: true,
  trackingUrl: null,
};

export default Tracking;
