import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

function TableRow({ data }) {
  const colSpan = data.length === 1 ? '100%' : data.length;
  return (
    <>
      <tr>
        {data.map(({ header }) => (
          <th
            key={header}
            colSpan={colSpan}
            style={{ textAlign: 'center', verticalAlign: 'baseline' }}
          >
            <Typography variant="caption" align="center">
              {header}
            </Typography>
          </th>
        ))}
      </tr>
      <tr>
        {data.map(({ cell }) => (
          <td
            key={cell}
            colSpan={colSpan}
            style={{
              textAlign: 'center',
              verticalAlign: 'baseline',
              width: '50%',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: '0.9rem', md: '1rem' },
                lineHeight: { xs: '1.2', md: '1.6' },
              }}
            >
              {cell}
            </Typography>
          </td>
        ))}
      </tr>
    </>
  );
}
TableRow.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      cell: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
export default TableRow;
