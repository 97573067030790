import styled from '@mui/material/styles/styled';
import PropTypes from 'prop-types';

const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.stepper.disabled,
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.stepper.active,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.stepper.completed,
  }),
}));

function CustomStepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icon}
    </StepIconRoot>
  );
}

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

CustomStepIcon.defaultProps = {
  active: false,
  className: '',
  completed: false,
  icon: <div />,
};

export default CustomStepIcon;
