import { Route, Routes } from 'react-router-dom';

import Error from './components/Error';
import TrackingController from './views/TrackingController';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/:trackingId" element={<TrackingController />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default AppRoutes;
