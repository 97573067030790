import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import deliveryNotFoundImage from '../assets/images/delivery-not-found.svg';
import Error from '../components/Error';
import Loading from '../components/Loading';
import GET_DELIVERY_BY_ID from '../gql/getDelivery';
import statusIconMapping from '../helpers/statusStepperIconMapping';
import Table from './Tracking';

const DELIVERY = 'DELIVERY';

function TrackingController() {
  const ERROR_NOT_FOUND = 'Delivery object not found';

  const { trackingId } = useParams();
  const { loading, data, error } = useQuery(GET_DELIVERY_BY_ID, {
    variables: { id: trackingId },
  });

  if (error?.message === ERROR_NOT_FOUND) {
    return (
      <Error
        message="No pudimos encontrar la información de tu pedido"
        img={deliveryNotFoundImage}
      />
    );
  }
  if (error) {
    return <Error />;
  }
  if (loading) {
    return <Loading />;
  }
  const {
    getDeliveryById: {
      dropOffAddress,
      deliveryTime,
      seller,
      courier,
      orderNumber,
      status,
      trackingUrl,
      statusHistory,
      deliveryMethod,
    },
  } = data;
  const hasDelivery = deliveryMethod === DELIVERY;

  const statusHistoryIcon = statusIconMapping(statusHistory);
  return (
    <Table
      dropOffAddress={dropOffAddress}
      deliveryTime={deliveryTime}
      seller={seller}
      orderNumber={orderNumber}
      status={status}
      trackingUrl={trackingUrl}
      courier={courier}
      statusHistory={statusHistoryIcon}
      hasDelivery={hasDelivery}
    />
  );
}
export default TrackingController;
