import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#ea5b27',
    },
    stepper: {
      active: '#ea5b27',
      completed: '#ea5b27',
      disabled: '#C2C2C3',
      line: '#eaeaf0',
    },
  },
  typography: {
    fontFamily: 'acumin-pro,sans-serif',
    caption: {
      color: '#999',
    },
    body1: {
      fontWeight: 600,
      fontSize: '0.8rem',
    },
    h2: {
      fontWeight: 800,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#999',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: '#C2C2C3',
          '&.Mui-active': {
            color: '#ea5b27',
          },
          '&.Mui-completed': {
            color: '#999',
          },
        },
        labelContainer: {
          width: 'inherit',
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
  },
});

export default theme;
