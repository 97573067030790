import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import ColumnContainer from './containers/ColumnContainer';
import MainContainer from './containers/MainContainer';

function Loading() {
  return (
    <MainContainer customStyle={{ boxShadow: 'none' }}>
      <ColumnContainer>
        <CircularProgress color="secondary" />
        <Typography align="center" variant="caption" sx={{ fontSize: 20 }}>
          Estamos buscando información de tu pedido
        </Typography>
      </ColumnContainer>
    </MainContainer>
  );
}
export default Loading;
