import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function ColumnContainer({ children, sx }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

ColumnContainer.propTypes = {
  children: PropTypes.node.isRequired,

  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};
ColumnContainer.defaultProps = {
  sx: {},
};

export default ColumnContainer;
