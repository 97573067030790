import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function ExternalCourierContainer({ courier: { name, logoUrl }, trackingUrl }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {name && (
          <Typography variant="caption" sx={{ mr: 1 }}>
            Encargado del despacho:
          </Typography>
        )}
        {logoUrl ? (
          <img src={logoUrl} alt="logo-courier" style={{ width: 100 }} />
        ) : (
          <Typography variant="caption">{name} </Typography>
        )}
      </Box>
      {trackingUrl && (
        <Link
          href={trackingUrl}
          target="_blank"
          rel="noopener noreferrer"
          variant="body2"
        >
          Haz click aquí para seguir tu orden desde el courier
        </Link>
      )}
    </Box>
  );
}
ExternalCourierContainer.propTypes = {
  courier: PropTypes.shape({
    name: PropTypes.string,
    logoUrl: PropTypes.string,
  }).isRequired,
  trackingUrl: PropTypes.string,
};
ExternalCourierContainer.defaultProps = {
  trackingUrl: null,
};
export default ExternalCourierContainer;
