import { ApolloClient } from '@apollo/client';

import cache from './cache';

const { REACT_APP_VELA_API_URL } = process.env;

const client = new ApolloClient({
  uri: REACT_APP_VELA_API_URL,
  cache,
});

export default client;
