import { InMemoryCache } from '@apollo/client';

import dateParser from '../helpers/dateParser';

const cache = new InMemoryCache({
  typePolicies: {
    Status: {
      fields: {
        updatedAt: {
          read(updatedAt) {
            return dateParser(updatedAt);
          },
        },
      },
    },
    Delivery: {
      fields: {
        deliveryTime: {
          read(deliveryTime) {
            return dateParser(deliveryTime);
          },
        },
      },
    },
  },
});

export default cache;
