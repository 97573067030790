import DoneAllIcon from '@mui/icons-material/DoneAll';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import OutboxIcon from '@mui/icons-material/Outbox';

const ICON_MAPPING = {
  readyAtStore: <OutboxIcon />,
  outForDelivery: <LocalShippingIcon />,
  delivered: <DoneAllIcon />,
};

function statusIconMapping(statusStepper) {
  const statusStepperIcon = statusStepper.map((step) => ({
    ...step,
    icon: ICON_MAPPING[step.value],
  }));
  return statusStepperIcon;
}
export default statusIconMapping;
