import { gql } from '@apollo/client';

const GET_DELIVERY_BY_ID = gql`
  query getDeliveryById($id: ID!) {
    getDeliveryById(id: $id) {
      orderNumber
      dropOffAddress
      trackingUrl
      seller {
        name
        branded
      }
      status
      statusHistory {
        value
        label
        updatedAt
      }
      courier {
        name
        logoUrl
      }
      deliveryMethod
    }
  }
`;
export default GET_DELIVERY_BY_ID;
