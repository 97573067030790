import ThemeProvider from '@mui/material/styles/ThemeProvider';

import AppRoutes from './Routes';
import theme from './styles/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
