import Card from '@mui/material/Card';
import PropTypes from 'prop-types';

function MainContainer({ children, customStyle }) {
  return (
    <Card
      style={customStyle}
      sx={{
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: {
          xs: '0px',
          md: '20px',
        },
        boxShadow: {
          xs: 'none',
          md: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        },
      }}
    >
      {children}
    </Card>
  );
}

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  customStyle: PropTypes.object,
};

MainContainer.defaultProps = {
  customStyle: {},
};

export default MainContainer;
