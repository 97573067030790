import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import CustomConnector from './CustomConnector';
import CustomStepIcon from './CustomStepIcon';

function DeliveryStepper({ steps, activeStepValue, optional }) {
  const activeStep = steps.findIndex((step) => step.value === activeStepValue);
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<CustomConnector />}
      sx={{ width: { xs: '100%', md: 400 } }}
    >
      {steps.map((step) => (
        <Step key={step.value}>
          <StepLabel
            StepIconComponent={(props) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <CustomStepIcon {...props} icon={step.icon} />
            )}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>{step.label}</Typography>
              <Typography variant="caption" align="center">
                {step[optional]}
              </Typography>
            </Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

DeliveryStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.node,
    }),
  ).isRequired,
  activeStepValue: PropTypes.string.isRequired,
  optional: PropTypes.string,
};

DeliveryStepper.defaultProps = {
  optional: undefined,
};

export default DeliveryStepper;
