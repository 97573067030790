import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import serverErrorImage from '../assets/images/server-error.svg';
import logo from '../assets/images/store-central.png';
import ErrorContainer from './containers/ErrorContainer';
import MainContainer from './containers/MainContainer';

function Error({ message, img }) {
  return (
    <MainContainer customStyle={{ boxShadow: 'none' }}>
      <ErrorContainer>
        <Box
          component="img"
          sx={{
            height: 'auto',
            width: { xs: 250, md: 350 },
          }}
          alt="serverError"
          src={img}
        />
        <Box
          sx={{
            alignItems: { xs: 'center', md: 'flex-start' },
            display: 'flex',
            flexDirection: 'column',
            width: { xs: 'auto', md: 500 },
          }}
        >
          <Typography color="secondary" variant="h2">
            Ups...
          </Typography>
          <Typography
            color="secondary"
            variant="h4"
            sx={{ textAlign: { xs: 'center', md: 'start' } }}
            component="div"
          >
            {message}
          </Typography>
          <Box
            component="img"
            sx={{
              height: 'auto',
              width: 150,
              mt: 5,
            }}
            alt="logo"
            src={logo}
          />
        </Box>
      </ErrorContainer>
    </MainContainer>
  );
}

Error.propTypes = {
  message: PropTypes.string,
  img: PropTypes.string,
};

Error.defaultProps = {
  message: 'Ocurrió un error inesperado',
  img: serverErrorImage,
};
export default Error;
